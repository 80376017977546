import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Web3 Website Template | Crypto Website Template | Uizard"
    category="Website Templates"
    url="/templates/website-templates/web3-crypto-website/"
    metaDescription="X Coin is a web3 website template for designing modern crypto websites: built to convert your audience. Customize your own web3 website design today."
    description="
    h2:Introducing X Coin… a crypto website template for your web3 crypto projects
    <br/>
    You bought Bitcoin and Ethereum before it was really cool? You know the difference between an NFT and ERC? 
    If you can answer yes to any of these questions, then our brand-new Uizard Pro design template is definitely what you are looking for to showcase your next web3 crypto project!
    <br/>
    h3:A workable prototype for your NFT launch, crypto website, or web3 project
    <br/>
    X Coin is a <a:https://uizard.io/templates/website-templates/>website design template</a> for designing modern crypto websites. 
    Built with one thing in mind; to convert your audience. 
    Crafted by our in-house designers, X Coin is a fictive crypto project built on the blockchain, featuring zero transaction fees, enabling instant payments, and of course, claiming to be fully decentralized and P2P.
    <br/>
    h3:Test and iterate your web3 blockchain project idea, collaboratively
    <br/>
    Building on the blockchain is not easy. Before you waste resources building and launching an NFT or crypto coin no one wants, design a quick website mockup, and test your assumptions with your intended audience. 
    This template will make it a breeze to enable you to visualize your vision and share it with the world! It's time to build.
    <br/>
    X Coin, a Uizard Pro template built in web 2.0 technologies for a web 3.0 crypto project.
    "
    pages={[
      "A crypto website landing page to announce your ICO",
      "A series of product pages to tell your audience why your web3 project is the best",
      'An "About" page',
      'A "Contact Us" page',
      "A blog page showcasing your web3 thought leadership",
    ]}
    projectCode="VRY3xZJOZoIyrMRoVzOr"
    img1={data.image1.childImageSharp}
    img1alt="Screenshot of X Coin, a web3 crypto website: cover"
    img2={data.image2.childImageSharp}
    img2alt="Screenshot of X Coin, a web3 crypto website: How does it work?"
    img3={data.image3.childImageSharp}
    img3alt="Screenshot of X Coin, a web3 crypto website: How to use?"
    img4={data.image4.childImageSharp}
    img4alt="Screenshot of X Coin, a web3 crypto website: blog"
    img5={data.image5.childImageSharp}
    img5alt="Image summary of the template for X Coin, a web3 crypto website"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(relativePath: { eq: "templates/XCoin/X-Coin-Cover.png" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: { eq: "templates/XCoin/X-Coin-How-does-it-work.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: { eq: "templates/XCoin/X-Coin-How-to-use.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(relativePath: { eq: "templates/XCoin/X-Coin-Blog.png" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(relativePath: { eq: "templates/XCoin/X-Coin-Summary.png" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
